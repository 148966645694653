html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family:
    'Nunito Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #bbc0d7;
}

code {
  font-family: 'source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;
}

.custom-calendar {
  margin: 0 auto;
}

input[text],
input:not([type]) {
  min-width: 4em; /* give a minimum of typing space */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  max-width: none !important;
}

/*
 * rc-menu: header dropdown navigation
 */
.rc-menu,
.rc-menu.rc-menu-sub {
  border: none; /* border causes the sub-navs to be offset down by the thickness of the border */
  box-shadow: 0 1px 3px 1px #1e1c774f;
}

.rc-menu-submenu-popup {
  padding: 0 4px;
}
.rc-menu-submenu {
  z-index: 20;
}
.rc-menu-horizontal > .rc-menu-submenu,
.rc-menu-horizontal > .rc-menu-item {
  vertical-align: top;
}

/*
 * react-calendar
 */
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #dee6fd; /* theme.colors.hover */
}
.react-calendar__navigation button[disabled] {
  background-color: #d3e1f0; /* theme.colors['status-disabled'] */
}
.react-calendar__month-view__days__day--weekend {
  color: #c72c41; /* theme.colors['neutral-4'] */
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__tile:disabled {
  background-color: #d3e1f0; /* theme.colors['status-disabled'] */
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #dee6fd; /* theme.colors.hover */
}
.react-calendar__tile--hasActive {
  background: #76baff; /* theme.colors.electricTeal */
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #6c82c9; /* theme.colors.brand */
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #76baff; /* theme.colors.electricTeal */
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #dee6fd; /* theme.colors.hover */
}

/*
 * material-ui select
 */

.MuiListItem-button:hover,
.MuiSelect-select:focus {
  background-color: #dee6fd !important; /* theme.colors.hover */
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #a5bafd !important; /* theme.colors.active */
}

/** overlapped boxes **/
.overlapped-box {
  min-width: 750px;
  max-width: 100%;
}

.tbl-100-hide-td1 {
  width: 100%;
}

.tbl-100-hide-td1 > tbody > tr > td:first-child {
  display: none;
}

.wd-100 {
  width: 100%;
}

.wd-auto {
  width: auto !important;
}

.td-block td:first-of-type,
.td-block td:nth-of-type(2) {
  white-space: nowrap;
}

.td-block td:first-of-type,
.td-block td:nth-of-type(3) {
  display: block;
  width: auto;
}

.td-block td:nth-of-type(3) {
  display: block;
}

button[role='menuitem'] span {
  font-size: 14px !important;
  line-height: 20px !important;
}

.font-14 {
  font-size: 14px !important;
  line-height: 20px !important;
}

.all-h-30 {
  height: 30px !important;
}

.middle-align {
  height: fit-content;
  align-self: center;
}

.td-cell-2 tbody:first-of-type td:nth-of-type(3) {
  display: table-cell !important;
  max-width: 200px;
  width: 50%;
  overflow: auto;
}

.mgl-10 {
  margin-left: 10px;
}

.white-label {
  color: #fff;
}

.white-checkbox + div {
  border-color: #fff;
}

.white-checkbox + div svg {
  stroke: #fff;
}

/*** Hide dial selection on number type inputs ***/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.sheets-bg {
  height: 100%;
  overflow-y: auto;
  background-image: url('images/sheets.jpeg');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
}

.hide-col1 thead tr th:first-child {
  display: none;
}

.hide-col1 tbody tr th:first-child {
  display: none;
}

.no-pad {
  padding: 0;
  padding-top: 0;
}

.left-table {
  min-width: 670px;
  max-width: 100%;
}

.right-table {
  min-width: 1160px;
  max-width: 100%;
}

.overflow-auto {
  overflow: auto;
}

.mgl-2 {
  margin-left: 2px;
}

div[role='menubar'] button[role='menuitem'] div {
  padding: 5px 12px 5px 12px;
}

.cell-focus {
  background-color: rgb(255 255 255);
  outline: 3px solid #3a3a3a;
  outline-offset: -3px;
}

.cell-focus input {
  color: #000;
}

.adjust-items-tbl thead tr th {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 9;
}

.error-box {
  padding: 20px;
  text-align: center;
  color: #e25454;
  font-weight: bolder;
  background-color: #fff9ed;
}

.loader {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
}

.loader-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #333;
  pointer-events: all;
  z-index: 888;
}
